import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './menu.component';
import { OrderOnlineComponent } from '../shopping-cart/order-online.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../login/_helpers/auth.guard';



const routes: Routes = [
  { path: 'menuitem', component: MenuComponent, children:[] },
  { path: 'orderonline', component: OrderOnlineComponent, children:[] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule { }