export class MenuItem {
    section: string;
    title: string;
    description = '';
    showDescription = true;
    qty: number;
    price: number;
    isVisible = true;
}

export class CateringMenu {
    title: string;
    description: string;
    showDescription: boolean;
    items: string[];
    qty: number;
    price: number;
    isVisible: boolean;

    constructor() {
        this.qty = 1;
        this.isVisible = true;
        this.description = '';
        this.items = [''];
        this.price = 0;
        this.title = '';
        this.showDescription = true;
    }
}
