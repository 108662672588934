import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FirebaseService} from 'src/app/services/firebase.service';

@Component({
  selector: 'app-menu-template',
  templateUrl: './menu-template.component.html',
  styleUrls: ['./menu-template.component.scss']
})
export class MenuTemplateComponent {
 @Input() menuItems: any[] = [];
 @Input() isDeleteVisible = false;
 @Output() deleteMenuTemplate: EventEmitter<any> = new EventEmitter<any>();

  constructor(public firebaseService: FirebaseService) {

  }

   _deleteMenuTemplate(item: any) {
     this.deleteMenuTemplate.emit(item);
   }

   updateMenuItem(m: any) {
     this.firebaseService.updateMenuItem(m.id, m).then(res => {
         console.log(res);
     });
   }
}

