<div id="home-top" (mouseenter) ="mouseEnter('home-top')">

<div class="img-top"> </div>

<div class="content" *ngIf="isAvailable">
  <div class="button-top" (click)="openOrderModal('custom-modal-1')">ORDER ONLINE</div>
  <div (click)="goToBookTable()" class="button-top" routerLink="/booking/booktable" skipLocationChange >BOOK A TABLE </div>
</div>

<div *ngIf="showTopText" class="text-content" style="margin-top: -28%;">
  <div class="text-top">BD DINE OPENING ON 21-08-2020</div>
</div>

</div>

<jw-modal id="custom-modal-1">
  <div style="min-height: 200px;">
    <span class="material-icons close-button" (click)="closeModal('custom-modal-1');"> cancel </span>
  <app-order-online></app-order-online>
</div>
</jw-modal>



