<div class="wrapper">
    <ng-container *ngFor="let m of menuItems">
      <div class="menu-card" (click)="menuItemClicked(m)">
        <div class="menu-box">
          <div style="height:35px;">
            <h2 style="font-size:18px; float:left"> {{m.data.title}} </h2>
            <h2 style="float: right; font-size:14px">${{m.data.price}}</h2>
          </div>
          <div *ngIf="m.data.showDescription" style="float: left;color:#7E7E7E"> {{m.data.description}}</div>
        </div>
      </div>
    </ng-container>
  </div>

