<div class="footer">
  <div id="footer-id" class="footer-content">
    <div class="item-description" style="min-height:100px !important; margin-left: 2%;width: auto;">
      <i class="fa fa-map-marker" aria-hidden="true" style="color: red; padding-right: 14px;"></i> 82/170  Flemington road, Harrison, ACT 2914
      <br />
      <i class="fas fa-phone" style="color: red; padding-right: 10px;"></i> 0488789441, (02) 6174 4746
      <br />
      <i class="fas fa-envelope" style="color: red; padding-right: 10px;"></i> bddinecanberra@gmail.com
    </div>


    <div id="separator-id" style="border-left: 2px solid red; height: 50px; margin-top: 2.5% ;"></div>

    <div class="item-description" style="min-height:100px !important; margin-left: 2%;width: auto;">
      <u><h2>Opening Hours:</h2></u>
      <ng-container *ngFor="let item of openingHours">
        {{item.dateTime}}
        <br />
      </ng-container>
    </div>

  </div>
</div>
