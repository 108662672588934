import {Component, OnInit} from '@angular/core';

import {AuthService} from './_services/auth.service';


@Component({selector: 'app-login', templateUrl: 'login.component.html'})
export class LoginComponent implements OnInit {

    constructor(
      public authService: AuthService
    ) { }

    ngOnInit() { }

}
