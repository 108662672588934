import {Component, OnDestroy, OnInit} from '@angular/core';
import {FirebaseService} from '../services/firebase.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-home-catering',
    templateUrl: './home-catering.component.html',
    styleUrls: ['./home-catering.component.scss']
})
export class HomeCateringComponent implements OnInit, OnDestroy {
    cateringMenuItems: any[] = [];
    subscriptions: Subscription = new Subscription();

    constructor(private firebaseService: FirebaseService) {

    }

    ngOnInit() {
    }

    mouseEnter(e: any) {
        this.firebaseService.setFloatButton(true);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
