import {Injectable} from '@angular/core';

@Injectable()
export class UtilityService {

    constructor() {
    }

    validateEmail(email: string) {
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validatePhone(phone: string) {
        const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        return re.test(phone);
    }

    getProcessedCategories(categories) {
        const allCategories = [];
        categories?.map(x => ({id: x.payload.doc.id, data: x.payload.doc.data()}))
            .forEach((value: any) => {
                allCategories.push({
                    sortOrder: value.data.sortOrder,
                    category: value.data.category,
                    action: 'save',
                    id: value.id
                });
            });
        return allCategories.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    getCategorizedMenuItems(data: any, categories: any) {
        const allMenuItems = [];
        const menuItems = [];
        data?.map(x => ({id: x.payload.doc.id, data: x.payload.doc.data()}))
            .forEach((value: any) => {
                allMenuItems.push({
                    isVisible: value.data.isVisible,
                    nameToSearch: value.data.nameToSearch,
                    price: value.data.price,
                    qty: value.data.qty,
                    section: value.data.section,
                    sectionId: categories?.find(cat => cat.category.toLowerCase()
                        === value.data.section.toLowerCase())?.sortOrder,
                    title: value.data.title,
                    id: value.id,
                    description: value.data.description,
                    showDescription: value.data.showDescription
                });
            });

        const groupedMenuItems = allMenuItems?.reduce((groups, item) => {
            const group = (groups[item.section] || []);
            group.push(item);
            groups[item.section] = group;
            return groups;
        }, {});

        (categories || []).forEach(cat => {
            const val = groupedMenuItems[cat.category.toLowerCase()];
            if (val?.length) {
                menuItems.push({
                    category: cat.category,
                    value: val
                });
            }
        });

        return menuItems;
    }

}
