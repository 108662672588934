<div (mouseenter)="mouseEnter('weeklycatering')" class="container">
    <div class="row">
        <div class="col-xl-6 col-lg-6 mb-30">
            <div class="benefits-wrapper">
                <div class="benefits-section">
                    <span>Check our weekly Special</span>
                    <h1>Weekly Catering</h1>
                    <p></p>
                    <p>Weekly catering takes away your burden of cooking. We have special deal to cater your need
                        in each week. We have several vegetarian and non-vegetarian
                        menu packages available, and can easily design a menu for your specific weekly catering need.
                        Please check our facebook page for each week's deal.
                        We are committed in doing everything we can to make sure your Food experience with us
                        is completely satisfying.
                    </p>
                    <p></p>
                </div>
                <img *ngIf="this.imgUrl" alt=""
                     src="https://appdeveloperenv.blob.core.windows.net/assets/about-us-5.png"
                     style="max-width: 100%">
            </div>

        </div>
        <div class="col-xl-6 col-lg-6">
            <div class="benefits-img mb-30">
                <img *ngIf="!this.imgUrl" alt=""
                     src="https://appdeveloperenv.blob.core.windows.net/assets/about-us-5.png">
                <img *ngIf="this.imgUrl" [src]="this.imgUrl" alt="weekly menu" style="width: 100%; height: auto;">
            </div>
        </div>

    </div>
</div>
