import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {FileUpload} from './file-upload';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
    private basePath = '/uploads';
    private collection = 'fileUploadInfo';
    private fileLists = null;

    constructor(private db: AngularFirestore, private storage: AngularFireStorage) {
    }

    pushFileToStorage(fileUpload: FileUpload): Observable<number | undefined> {
        const filePath = `${this.basePath}/${fileUpload.file.name}`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, fileUpload.file);

        uploadTask.snapshotChanges().pipe(
            finalize(() => {
                storageRef.getDownloadURL().subscribe(downloadURL => {
                    fileUpload.url = downloadURL;
                    fileUpload.name = fileUpload.file.name;
                    this.saveFileData(fileUpload);
                });
            })
        ).subscribe();

        return uploadTask.percentageChanges();
    }

    getFileList() {
        if (!this.fileLists) {
            this.fileLists = this.db.collection(this.collection).snapshotChanges();
        }
        return this.fileLists;
    }

    deleteFile(fileUpload: FileUpload): void {
        this.deleteFileDatabase(fileUpload.key)
            .then(() => {
                this.deleteFileStorage(fileUpload.name);
            })
            .catch(error => console.log(error));
    }

    private saveFileData(fileUpload: FileUpload): void {
        this.db.collection(this.collection).add({
            name: fileUpload.name,
            url: fileUpload.url,
            file: {
                lastModified: fileUpload.file.lastModified,
                name: fileUpload.file.name,
                size: fileUpload.file.size,
                type: fileUpload.file.type,
            }
        });
    }

    private deleteFileDatabase(key: string): Promise<void> {
        return this.db.collection(this.collection).doc(key).delete();
    }

    private deleteFileStorage(name: string): void {
        const storageRef = this.storage.ref(this.basePath);
        storageRef.child(name).delete();
    }
}
