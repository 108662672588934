<ng-container *ngIf="orderItems.length>0">
<span  (click)="showCartItems=!showCartItems"
  class="material-icons cart-icon" [matBadge]="total_cartItems" matBadgeOverlap="true">shopping_cart</span>
</ng-container>


<div [@openClose] *ngIf="showCartItems" class="shopping-cart">

  <div *ngIf="!isOrderSuccessfullyPlaced">
    <span class="material-icons cart-close" style="float: right;margin-right: -50px;" (click)="cancelOrder()"
      title="Remove all items">highlight_off</span>
    <div style="margin: 10px">Your order details</div>
    <div style="width:100%;border-bottom: 2px solid black"></div>
    <div *ngFor="let i of orderItems; let ii = index" style="margin-left:20px;font-size: 12px;" class="col-md-12">
      <div style="clear: both;">
        <div style="float: left;margin-left:-20px; color: #7E7E7E; cursor: pointer;" (click)=removeItem(i)>x</div>
        <div style="float: left;"> {{ii+1}}. {{i.data.title}} </div>
        <div style="float: right; margin-right: 20px"> {{i.data.qty}} x {{i.data.price}}</div>
      </div>
    </div>

    <div style="clear: both"></div>
    <div style="width:100%;border-bottom: 2px solid black"></div>
    <div style="margin-left: 20px;margin-bottom: 100px;" class="col-md-12">
      <div style="float: left;">Total: </div>
      <div style="float: right;margin-right: 20px">${{total_price}}</div>
    </div>
    <div style=" margin: 0 67px 20px;">
      <button mat-raised-button style="float: left; background-color: green;" (click)="placeOrder()">Place
        Order</button>
      <button mat-raised-button style="float: left; background-color: green; margin-left: 10px;"
        (click)="showCartItems=!showCartItems">Cancel</button>
    </div>

    <div *ngIf="isOrderPlaced">

      <div style="padding-top: 16px;">
        <input type="text" [ngClass]="{'error-border': error.name === true}" placeholder="Name-required"
          [(ngModel)]="customer.name" class="input-layout">

        <div *ngIf="errorMsg.name" style="clear: both;font-size: 12px;color: red;margin-left: 10px;">*{{errorMsg.name}}
        </div>
        <input type="text" [ngClass]="{'error-border': error.email === true}" placeholder="Email-required"
          [(ngModel)]="customer.email" class="input-layout">

        <div *ngIf="errorMsg.email" style="clear: both;font-size: 12px;color: red;margin-left: 10px;">
          *{{errorMsg.email}}</div>

        <input type="text" [ngClass]="{'error-border': error.phone === true}" placeholder="Phone-required"
          [(ngModel)]="customer.phone" class="input-layout">

        <div *ngIf="errorMsg.phone" style="clear: both;font-size: 12px;color: red;margin-left: 10px;">
          *{{errorMsg.phone}}</div>

        <input type="text" [ngClass]="{'error-border': error.address === true}" placeholder="Address-optional"
          [(ngModel)]="customer.address" class="input-layout">



      </div>
      <div style="padding:15px 0px 0px 104px;">
        <button mat-raised-button style="float: left; background-color: green;" (click)="processOrderDetails()">Submit
          Order</button>
      </div>

    </div>

  </div>
  <div *ngIf="isOrderSuccessfullyPlaced" style="clear: both;font-size: 12px;color: black ;margin-left: 34px;margin-top: 118px;">
    Thank you for your order. Your order is confirmed.
    <div style="padding:15px 0px 0px 104px;">
      <button mat-raised-button style="float: left; background-color: green;" (click)="showCartItems=!showCartItems">Close</button>
    </div>
  </div>
</div>
