<div class="container" (mouseenter) ="mouseEnter('catering')">
    <div class="row">
        <div class="col-xl-6 col-lg-6 mb-30">
            <div class="benefits-wrapper">
                <div class="benefits-section">
                    <span>Check our catering menu</span>
                    <h1>Catering</h1>
                    <p></p><p>Food is a special part of every occasion. We provide food for all types small 
                        to big personal and business occasions. We have several vegetarian and non-vegetarian 
                        menu packages available, and can easily design a menu for your specific event. 
                        We are committed in doing everything we can to make sure your Food experience with us 
                        is completely satisfying. 
                        <br/>We take customized catering order for parties or wedding. Please call 0411395289 for 
                        more details.
                </p>
                    <p></p>
                </div>
            </div>

        </div>
        <div class="col-xl-6 col-lg-6">
            <div class="benefits-img mb-30">
                <img src="https://appdeveloperenv.blob.core.windows.net/assets/about-us-4.png" alt="">
            </div>
        </div>
        <app-catering-menu-template [isDeleteVisible]="false">
        </app-catering-menu-template>
    </div>
</div>
