import { Component, OnInit} from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { OrderDetail } from './shopping-cart.model';
import { UtilityService } from '../services/utility.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', animate(500))
    ])
  ]
  
})
export class ShoppingCartComponent implements OnInit {

  orderItems: any[] = [];
  orderDetails: any= {};
  showCartItems: boolean = false;
  lastOrderId: number;

  constructor(private firebaseService: FirebaseService, private utilityService: UtilityService){

  }

  ngOnInit() {
    this.initCart();
    this.firebaseService.getValue()
      .subscribe(item => 
      {
        if (item) {
          this.menuClicked(item);
        }
      });
      this.firebaseService.getAllOrders().subscribe((data: any[])=>{
        var orderId = (data || []).map(x => { return x.id });
        this.lastOrderId = Math.max(...orderId) + 1;
      });
  }

  initCart(){
    var shoppingCart = JSON.parse(localStorage.getItem('bddineshoppingcart'));
    if((shoppingCart || []).length> 0){
      this.orderItems = shoppingCart;
      this.calculatePrice();
    }
  }

  total_price: number = 0;
  total_cartItems: number = 0;

  menuClicked(item: any) {
 
    var itemExist = this.orderItems.find(x=>x.data.title == item.data.title);
    if(itemExist){
      itemExist.data.qty = +itemExist.data.qty + 1;
    }
    else{
      this.orderItems.push(item);
    }

    this.calculatePrice();

    this.saveOrderInLocalStorage();
  }

  removeItem(item: any){
    var index = this.orderItems.findIndex(x=>x.data.title == item.data.title);
    if (index > -1) {
      if (this.orderItems[index].data.qty > 1)
        this.orderItems[index].data.qty = +this.orderItems[index].data.qty - 1;
      else {
        this.orderItems.splice(index, 1);
      }
    }

   
    this.calculatePrice();
    this.saveOrderInLocalStorage();

  }

  cancelOrder(){
     this.resetOrderCart();
  }

  saveOrderInLocalStorage(){
    if(this.orderItems.length> 0){
      localStorage.setItem('bddineshoppingcart', JSON.stringify(this.orderItems));
    }
  }

  calculatePrice(){
    this.total_price = this.orderItems
    .map(x=>parseFloat(x.data.price) * (+x.data.qty) )
    .reduce((a,b)=>a+b,0);
    this.total_cartItems = this.orderItems
    .map(x=> +x.data.qty )
    .reduce((a,b)=>a+b,0);
  }

  isOrderPlaced: boolean= false;
  customer: any = {};

  placeOrder(){
    if(this.orderItems.length > 0){
      this.isOrderPlaced = true;
    }
  }
  isOrderSuccessfullyPlaced: boolean = false;
  processOrderDetails(){

     if(this.isValidateCustomerData()){
  
     this.orderDetails = {
       id: this.lastOrderId,
       customerName: this.customer.name,
       customerPhone: this.customer.phone,
       email: this.customer.email,
       customerAddress: this.customer.address,
       paymentMethod: 'Pay over the counter',
       deliveryOption: 'Pick up'
     }; 
    this.emailDetailsHTML();

    
     this.firebaseService.placeOrder(this.orderDetails).then(
      res => {
        this.isOrderSuccessfullyPlaced = true;
        setTimeout(() => this.resetOrderCart(), 2000);
       
      }
     );
    } 

  }
  resetOrderCart(){
    this.orderItems = [];
    this.orderDetails = new OrderDetail();
    this.customer = {}; 
    this.showCartItems = false;
    this.isOrderSuccessfullyPlaced = false;
    this.error = {};
    this.errorMsg = {};
    this.isOrderPlaced = false;
    this.total_price = 0;
    localStorage.removeItem('bddineshoppingcart');
  }

  emailDetailsHTML(){
    
    this.orderDetails.subject = `Order confiremd: order`;
      
    var itemStr:string= '<ul>';
    var i = 0;
    this.orderItems.forEach(d=>
      {
        var price = d.data.price;
        var decimalPlace = price.split('.')[1];
        decimalPlace = !decimalPlace? ".00": "";
        i = i + 1;
        itemStr= itemStr + `<li>${i}. ${d.data.title}    ${d.data.qty}x$${d.data.price}${decimalPlace}</li>`
      });
    itemStr = itemStr + '</ul>'
    var emailDetailsHtml = `
    <h2>Thank you for you order. We will contact you once your order is processed.</h2>
    <h1>Order details:</h1>
    ${itemStr}
    <div style="width:600px; border-bottom: 1px solid grey"></div>
    <p>Totat: $${this.total_price}${this.total_price.toString().split('.')[1]==undefined? '.00': ""}</p>
    <p>Payment method: ${this.orderDetails.paymentMethod}</p>
    <p>Delivery option: ${this.orderDetails.deliveryOption}</p>
    `;
   this.orderDetails.details= emailDetailsHtml;

}

  error: any={};
  errorMsg: any= {};
  isValidateCustomerData() : boolean{

    this.error.name = !this.customer.name;
    this.error.email = !this.customer.email || !this.utilityService.validateEmail(this.customer.email);
    this.error.phone = !this.customer.phone || !this.utilityService.validatePhone(this.customer.phone);
    
    this.error.isInvalid = this.error.name || this.error.email || this.error.phone;
    this.errorMsg = {};
    if(this.error.name) {
      this.errorMsg.name = "Please correct the name";
    }
    if(this.error.email) {
      this.errorMsg.email = "Please correct the email address"
    }
    if(this.error.phone) {
      this.errorMsg.phone = "Please correct the phone number"
    }

    return !this.error.isInvalid;
  }



}

