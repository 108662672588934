import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';


@Component({
  selector: 'app-home-about-us',
  templateUrl: './home-about-us.component.html',
  styleUrls: ['./home-about-us.component.scss']
})
export class HomeAboutUsComponent implements OnInit {

  constructor(private firebaseService: FirebaseService){

  }
  isFloatButtonVisible: boolean = false;

  ngOnInit(){
 
  }

  onFloatClick(){

  }

  mouseEnter(id: any){
     this.firebaseService.setFloatButton(true);
  }
}