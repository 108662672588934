import { Component,  Input, Output, EventEmitter  } from '@angular/core';
import { MenuTemplateComponent } from '../menu/menu-template/menu-template.component';


@Component({
  selector: 'app-order-template',
  templateUrl: './order-template.component.html',
  styleUrls: ['./order-template.component.scss']
})
export class OrderTemplateComponent extends MenuTemplateComponent{

  @Output() menuClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() menuItems: any[] = [];

  menuItemClicked(m: any){
    this.menuClicked.emit(m);
  }
}

