import {Component, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    selectedMenu = 0;

    constructor(@Inject(DOCUMENT) private document: Document,
                private router: Router) {
    }

    getSelectedMenu(m: any) {
        let elmnt;
        this.selectedMenu = m;
        if (this.selectedMenu === 1) {
            elmnt = document.getElementById('catering');
            elmnt.scrollIntoView();
        }
        if (this.selectedMenu === 2) {
            elmnt = document.getElementById('weeklycatering');
            elmnt.scrollIntoView();
        }
    }

    goToMenu(): void {
        this.router.navigate(['fullmenu', 'menuitem'], {skipLocationChange: true});
    }

    goToYoello() {
        this.document.location.href = 'https://pay.yoello.com/v/bddine';
    }

}
