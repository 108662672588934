import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {OrderDetail} from '../shopping-cart/shopping-cart.model';
import {HttpClient} from '@angular/common/http';
import {ContactUs} from '../login/_models/ContactUs';
import {TableBooking} from '../login/_models/TableBooking';
import {CateringMenu, MenuItem} from '../login/_models/MenuItem';
import {User} from '../login/_models';
import {TableBookingSlot} from '../login/_models/TableBookingSlot';


@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    private orderInfo: BehaviorSubject<any>;
    private viewportChanged: BehaviorSubject<any>;
    private floatButton: BehaviorSubject<boolean>;
    private allMenuItems: any = null;
    private openingHours: any = null;
    private bannerText: any = null;
    private cateringMenuItems: any = null;

    constructor(public db: AngularFirestore, private http: HttpClient) {
        this.orderInfo = new BehaviorSubject<boolean>(null);
        this.viewportChanged = new BehaviorSubject<boolean>(null);
        this.floatButton = new BehaviorSubject<boolean>(false);
    }

    addContactUs(item: ContactUs) {
        return this.db.collection('contactus').add(
            {
                name: item.name,
                email: item.email,
                phone: item.message,
                message: item.message,
                contactId: item.contactId || 1
            }
        );
    }

    addTableBooking(item: TableBooking) {
        return this.db.collection('tablebooking').add(
            {
                name: item.name,
                email: item.email,
                phone: item.phone,
                message: item.message,
                numPeople: item.numPeople,
                date: item.date,
                slot: item.slot,
                bookingId: item.bookingId || 1,
                slotId: item.slotId
            }
        );
    }

    getTableBookingSlots() {
        return this.db.collection('tableBookingSlots').snapshotChanges();
    }

    getTableBookingSlotValueChanges() {
        return this.db.collection('tableBookingSlots').valueChanges();
    }

    addTableBookingSlot(item: TableBookingSlot) {
        return this.db.collection('tableBookingSlots').add(
            {
                id: item.id,
                value: item.value,
                numPeople: item.numPeople
            });
    }

    deleteTableBookingSlot(itemKey: any) {
        return this.db.collection('tableBookingSlots').doc(itemKey).delete();
    }

    getPreviousTableBookings() {
        return this.db.collection('tablebooking').valueChanges();
    }

    getAllContactUsItems() {
        return this.db.collection('contactus').valueChanges();
    }

    getAllOrders() {
        return this.db.collection('orders').valueChanges();
    }

    getAllUsers() {
        return this.db.collection('users').valueChanges();
    }

    registerUser(user: User) {
        return this.db.collection('users').add(
            {
                id: user.id,
                type: 'member',
                username: user.username,
                password: user.password,
                firstname: user.firstName,
                lastname: user.lastName
            }
        );
    }

    setFloatButton(v: boolean): void {
        this.floatButton.next(v);
    }

    getFloatButton(): Observable<boolean> {
        return this.floatButton.asObservable();
    }

    getValue(): Observable<any> {
        return this.orderInfo.asObservable();
    }

    setViewPort(newValue: any): void {
        this.viewportChanged.next(newValue);
    }

    getViewPort(): Observable<any> {
        return this.viewportChanged.asObservable();
    }

    setValue(newValue: any): void {
        this.orderInfo.next(newValue);
    }

    getAllMenuItems() {
        if (!this.allMenuItems) {
            this.allMenuItems = this.db.collection('allMenuItems').snapshotChanges();
        }
        return this.allMenuItems;
    }

    getOpeningHours() {
        if (!this.openingHours) {
            this.openingHours = this.db.collection('openingHours').snapshotChanges();
        }
        return this.openingHours;
    }

    getBannerText() {
        if (!this.bannerText) {
            this.bannerText = this.db.collection('topBannerTexts').snapshotChanges();
        }
        return this.bannerText;
    }

    getAllCategories() {
        return this.db.collection('menuCategory').snapshotChanges();
    }

    addMenu(item: MenuItem) {
        return this.db.collection('allMenuItems').add(
            {
                title: item.title,
                section: item.section,
                price: item.price,
                qty: item.qty,
                isVisible: item.isVisible,
                showDescription: item.showDescription,
                description: item.description
            }
        );
    }

    addCateringMenu(item: CateringMenu) {
        return this.db.collection('cateringMenuItems').add(
            {
                title: item.title,
                items: item.items,
                price: item.price,
                qty: item.qty,
                isVisible: item.isVisible,
                showDescription: item.showDescription,
                description: item.description
            }
        );
    }

    updateCateringMenu(menuKey: string, item: CateringMenu) {
        return this.db.collection('cateringMenuItems').doc(menuKey).set(
            {
                title: item.title,
                items: item.items,
                price: item.price,
                qty: item.qty,
                isVisible: item.isVisible,
                showDescription: item.showDescription,
                description: item.description
            }
        );
    }

    getCateringMenuItems() {
        if (!this.cateringMenuItems) {
            this.cateringMenuItems = this.db.collection('cateringMenuItems').snapshotChanges();
        }
        return this.cateringMenuItems;
    }

    placeOrder(item: OrderDetail) {
        return this.db.collection('orders').add(
            {
                id: item.id,
                customerName: item.customerName,
                customerAddress: item.customerAddress || '',
                customerPhone: item.customerPhone,
                email: item.email,
                details: item.details,
                paymentMethod: item.paymentMethod,
                deliveryOption: item.deliveryOption,
                subject: item.subject
            }
        );
    }

    deleteMenuItem(menuKey: any) {
        return this.db.collection('allMenuItems').doc(menuKey).delete();
    }

    deleteCateringMenuItem(menuKey: any) {
        return this.db.collection('cateringMenuItems').doc(menuKey).delete();
    }

    updateMenuItem(menuKey, item) {
        return this.db.collection('allMenuItems').doc(menuKey).set({
            title: item.title,
            section: item.section,
            price: item.price,
            qty: item.qty,
            isVisible: item.isVisible,
            showDescription: item.showDescription,
            description: item.description
        });
    }

}
