<div class="wrapper">
<div *ngFor="let m of menuItems">
  <div class="menu-card">
    <div *ngIf="isDeleteVisible"style="cursor:pointer;float: right; margin-top: -35px;" >
      <div style="color:black; font-size: 16px;" >
        <input type="checkbox"  [(ngModel)]="m.isVisible" (change)="updateMenuItem(m)">
        <div (click)="_deleteMenuTemplate(m)" style="color:red; font-size: 16px; float: right; padding-left: 10px;">
          Delete(x)
        </div>
      </div>

    </div>
    <div class="menu-box">
      <div class="menu-text">
        <div class="menu-title"> {{m.title}} </div>
        <div class="menu-price">${{m.price}}</div>
      </div>
      <div *ngIf="m.showDescription" class="menu-description"> {{m.description}}</div>
      <button class="add-text" disabled="true">Add</button>
    </div>
  </div>
</div>
</div>

