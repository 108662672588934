
    <mat-menu #admin="matMenu">
        <div mat-menu-item routerLink="/admin/menubuilder" skipLocationChange>Build Menu</div>
        <div mat-menu-item routerLink="/admin/openinghours" skipLocationChange>Opening hours</div>
        <div mat-menu-item routerLink="/admin/topbannertext" skipLocationChange>Banner Text</div>
        <div mat-menu-item routerLink="/admin/vieworder" skipLocationChange>View Orders</div>
        <div mat-menu-item routerLink="/home" skipLocationChange>View Members</div>
    </mat-menu>

    <div class="wrapper" style="background-color: green;">

        <div id="content" >

            <nav class="navbar navbar-expand-lg navbar-light">
                <div class="navbar-toggler" id="sidebarCollapse" 
                        (click)="isSidebarOpen=!isSidebarOpen" style="margin-left:0px; cursor: pointer;">
                    <span class="navbar-toggler-icon">
                        <i class="fas fa-bars" style="color: honeydew;"></i>
                    </span>
                </div>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <a class="navbar-brand" routerLink="/home"  skipLocationChange><img src="https://appdeveloperenv.blob.core.windows.net/assets/bddinelogo1.png" style="width: 47px;height: 23px;"></a>
                    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li class="nav-item"><a class="nav-link" routerLink="/home"  skipLocationChange>Home</a></li>
                        <li class="nav-item"><a class="nav-link"  (click)="goToMenu()"  style="cursor: pointer;">Menu</a></li>
                        <li class="nav-item"><a (click)="goToYoello()" class="nav-link" style="cursor: pointer;">Order
                            and pay</a></li>
                        <li *ngIf="isAdminLoggedIn" class="nav-item"><a class="nav-link" [matMenuTriggerFor]="admin" style="cursor: pointer;">Admin</a></li>
                        <li *ngIf="!isAdminLoggedIn && !isUserLoggedIn" class="nav-item"><a class="nav-link"  routerLink="/login"  skipLocationChange>Member</a></li>
                        <li *ngIf="isUserLoggedIn" class="nav-item"><a class="nav-link"  routerLink="/member/home"  skipLocationChange>Member</a></li>
                        <li *ngIf="isUserLoggedIn || isAdminLoggedIn" class="nav-item"><a class="nav-link"  routerLink="/"  skipLocationChange><span (click)="logout()">Logout</span></a></li>
                        <li class="nav-item"><a class="nav-link"  routerLink="/contact/contactus"  style="cursor: pointer;" skipLocationChange ><span (click)="goToContactUs()">Contact us</span></a></li>
                    </ul>
                </div>
            </nav>
        </div>
        <nav *ngIf="isSidebarOpen" id="sidebar" (click)="isSidebarOpen=!isSidebarOpen">
            <div class="sidebar-header">
                <img src="https://appdeveloperenv.blob.core.windows.net/assets/bddinelogo1.png" style="width: 47px;height: 23px;">
                <span style="padding-left: 232px;"><i class="fas fa-times" style="color: gray;"></i> </span>
            </div>

            <ul class="list-unstyled components">

                <li class="active">
                    <a routerLink="/home" skipLocationChange data-toggle="collapse" aria-expanded="false">Home</a>
                </li>
                <li class="active">
                    <a (click)="goToYoello()" aria-expanded="false" data-toggle="collapse">Order and pay</a>
                </li>
                <li class="active">
                    <a (click)="goToMenu()" skipLocationChange data-toggle="collapse" aria-expanded="false">Menu</a>
                </li>
                <li *ngIf="isAdminLoggedIn" class="active">
                    <a aria-expanded="false" data-toggle="collapse" routerLink="/admin/menubuilder" skipLocationChange>Build
                        Menu</a>
                </li>
                <li *ngIf="isAdminLoggedIn" class="active">
                    <a aria-expanded="false" data-toggle="collapse" routerLink="/admin/openinghours" skipLocationChange>Opening
                        Hours</a>
                </li>
                <li *ngIf="isAdminLoggedIn" class="active">
                    <a aria-expanded="false" data-toggle="collapse" routerLink="/admin/topbannertext"
                       skipLocationChange>Banner Text</a>
                </li>
                <li *ngIf="isAdminLoggedIn" class="active">
                    <a aria-expanded="false" data-toggle="collapse" routerLink="/admin/vieworder"
                       skipLocationChange><span (click)="goToMenu()">View orders</span></a>
                </li>

                <li *ngIf="!isAdminLoggedIn && !isUserLoggedIn" class="nav-item">
                    <a class="nav-link" routerLink="/login" skipLocationChange>Member</a>
                </li>
                <li *ngIf="isUserLoggedIn" class="nav-item">
                    <a class="nav-link" routerLink="/member/home" skipLocationChange>Member</a>
                </li>

                <li class="active">
                    <a routerLink="/contact/contactus" skipLocationChange data-toggle="collapse" aria-expanded="false"><span (click)="goToContactUs()">Contact us</span></a>
                </li>
                <li *ngIf="isUserLoggedIn || isAdminLoggedIn" class="nav-item">
                    <a class="nav-link"  routerLink="/"  skipLocationChange><span (click)="logout()">Logout</span></a>
                </li>

               
            </ul>

        </nav>
    </div>



