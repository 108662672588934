import {Component, OnDestroy, OnInit} from '@angular/core';
import {FirebaseService} from '../services/firebase.service';
import {FileUploadService} from '../firebase-upload/file-upload.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-home-weekly-catering',
  templateUrl: './home-weekly-catering.component.html',
  styleUrls: ['./home-weekly-catering.component.scss']
})
export class HomeWeeklyCateringComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  imgUrl: string;

  constructor(private firebaseService: FirebaseService,
              private uploadService: FileUploadService) {

  }

  ngOnInit() {
    this.imgUrl = null;
    this.subscriptions.add(
        this.uploadService.getFileList().subscribe(data => {
          this.imgUrl = data?.map(x => x.payload.doc.data())?.[0]?.url;
        }));
  }

  mouseEnter(e: any) {
    this.firebaseService.setFloatButton(true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
