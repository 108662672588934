<div id="aboutus" class="container" (mouseenter) ="mouseEnter('aboutus')" >
    <div class="row">
                        <div class="col-xl-6 col-lg-6 mb-30">
            <div class="benefits-wrapper">
                <div class="benefits-section">
                    <span>An Authentic Bangladeshi Flavour</span>
                    <h1>About</h1>
                    <p></p><p>Our dishes come with an authentic Bangladeshi flavor.  
                        We are based in Canberra and serving the community with an delicate food experience. 
                        We cook with passion without compromising the quality and taste.
                        We have added a variety of food items which include Indian, Pakistani and Mediterranean. 
                        BD DINES special Kachchi Biryani is one of the best that you will get here. </p>
<p></p>
                </div>
            </div>
            
        </div>
        <div class="col-xl-6 col-lg-6">
            <div class="benefits-img mb-30">
                <img src="https://appdeveloperenv.blob.core.windows.net/assets/about-us-1.png" alt="">
            </div>
        </div>
         
    </div>
</div>
