import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'float-button',
  template: ` 
  <span class="button-visible">
  <a *ngIf="isButtonVisible$ | async" href="#" class="float">
  <span class="material-icons my-float">
  expand_less
  </span>
  </a>
  </span>
  `,
  styles:[`
  @media (max-width: 600px) {
    .button-visible{
     visibility: hidden;
    }

  }
  
  `]
})
export class FloatButtonComponent implements OnInit{
  isButtonVisible$:any;
  constructor(private firebaseService: FirebaseService){
    
  }
ngOnInit(){
  this.isButtonVisible$ = this.firebaseService.getFloatButton();
}
  
}
