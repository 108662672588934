import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-gallery-new',
  templateUrl: './gallery-new.component.html',
  styleUrls: ['./gallery-new.component.scss']
})
export class GalleryNewComponent {


  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,
    autoWidth: true,
    autoplayHoverPause: true,
  }


  slidesStore = [
    { id: 1, src: "https://appdeveloperenv.blob.core.windows.net/assets/gallery4.png", title: "Weekend Special", desc1: "Weeken special comes with different items like Khichuri, Omelette, Chicken/Beef etc." },
    { id: 2, src: "https://appdeveloperenv.blob.core.windows.net/assets/gallery10.png", title: "Mutton curry", desc1: "Mutton curry is made with a perfect combination of spices following  an authentic Bengali recipe." },
    { id: 3, src: "https://appdeveloperenv.blob.core.windows.net/assets/gallery11.png", title: "Special fish curry", desc1: "Ruhu/Mrigel fish curry is cooked with an excellent combination of spices onion, garlic and ginger. " },
    { id: 4, src: "https://appdeveloperenv.blob.core.windows.net/assets/gallery4.png", title: "Weekend Special", desc1: "Weeken special comes with different items like Khichuri, Omelette, Chicken/Beef etc." },
    { id: 5, src: "https://appdeveloperenv.blob.core.windows.net/assets/gallery10.png", title: "Mutton curry", desc1: "Mutton curry is made with a perfect combination of spices following  an authentic Bengali recipe." },
    { id: 6, src: "https://appdeveloperenv.blob.core.windows.net/assets/gallery11.png", title: "Special fish curry", desc1: "Ruhu/Mrigel fish curry is cooked with an excellent combination of spices onion, garlic and ginger. " },
    { id: 7, src: "https://appdeveloperenv.blob.core.windows.net/assets/gallery4.png", title: "Weekend Special", desc1: "Weeken special comes with different items like Khichuri, Omelette, Chicken/Beef etc." },
    { id: 8, src: "https://appdeveloperenv.blob.core.windows.net/assets/gallery10.png", title: "Mutton curry", desc1: "Mutton curry is made with a perfect combination of spices following  an authentic Bengali recipe." }
  ];


}

