<body style="background-color: white; height: auto;">
    <div *ngIf="bannerText" class="banner-style">
        <i class="far fa-bookmark" style="color: red;font-size: 19px; padding: 2px 15px 0px 15px;"></i>
        {{bannerText}}
    </div>
<app-nav-menu></app-nav-menu>
<app-shopping-cart></app-shopping-cart>
<float-button></float-button>
<router-outlet> </router-outlet>
</body>
