import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {NavMenuComponent} from './nav-bar/nav-menu.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScrollSpyDirective} from './scroll-spy.directive';
import {GalleryHomeTopComponent} from './home/gallery/gallery-home-top.component';
import {SharedModule} from './shared-module/shared.module';
import {AngularFireModule} from '@angular/fire';
import {environment} from 'src/environments/environment';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {HomeComponent} from './home/home.component';
import {HomeWeeklyCateringComponent} from './home/home-weekly-catering.component';
import {HomeCateringComponent} from './home/home-catering.component';
import {HomeAboutUsComponent} from './home/home-about-us.component';
import {FooterComponent} from './home/footer/footer.component';
import {GalleryNewComponent} from './home/gallery/gallery-new.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthGuard, ErrorInterceptor, fakeBackendProvider, JwtInterceptor} from './login/_helpers';
import {RouterModule} from '@angular/router';
import {FirebaseService} from './services/firebase.service';
import {FloatButtonComponent} from './home/float-button.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './login/register.component';
import {AlertComponent} from './login/alert.component';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AuthService} from './login/_services/auth.service';
import {ForgotPasswordComponent} from './login/forgot-password.component';
import {VerifyEmailComponent} from './login/verify-email.component';
import {MenuModule} from './menu/menu.module';


@NgModule({
  declarations: [
    AppComponent,
    ScrollSpyDirective,
    GalleryHomeTopComponent,
    NavMenuComponent,
    HomeComponent,
    HomeWeeklyCateringComponent,
    HomeCateringComponent,
    HomeAboutUsComponent,
      FooterComponent,
      GalleryNewComponent,
      FloatButtonComponent,
      LoginComponent,
      RegisterComponent,
      ForgotPasswordComponent,
      VerifyEmailComponent,
      AlertComponent

  ],
    imports: [
        BrowserModule,
        SharedModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot([
            {path: 'home', component: HomeComponent},
            {path: '', component: HomeComponent, pathMatch: 'full'},
            {path: 'fullmenu', loadChildren: () => import('./menu/menu.module').then(mod => mod.MenuModule)},
            {
                path: 'contact',
                loadChildren: () => import('./contact-us/contact-us.module').then(mod => mod.ContactModule)
            },
            {
                path: 'booking',
                loadChildren: () => import('./book-table/book-table-module').then(mod => mod.BookTableModule)
            },
            {path: 'member', loadChildren: () => import('./member/member.module').then(mod => mod.MemberModule)},
            {path: 'login', component: LoginComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'forgot-password', component: ForgotPasswordComponent},
            {path: 'verify-email-address', component: VerifyEmailComponent},
            {path: 'admin', loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)},
            {path: '**', component: HomeComponent}
        ]),
        MenuModule
    ],

    bootstrap: [AppComponent],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        AuthGuard,
        AuthService,
        // provider used to create fake backend
        fakeBackendProvider, FirebaseService],
})
export class AppModule { }
