import {NgModule} from '@angular/core';
import {MenuRoutingModule} from './menu-routing.module';
import {SharedModule} from '../shared-module/shared.module';
import {MenuComponent} from './menu.component';
import {CateringMenuTemplateComponent} from './catering-menu-template/catering-menu-template.component';
import {
    WeeklyCateringMenuTemplateComponent
} from './weekly-catering-menu-template/weekly-catering-menu-template.component';

@NgModule({
    imports: [
        MenuRoutingModule,
        SharedModule
    ],
    declarations: [
        MenuComponent,
        CateringMenuTemplateComponent,
        WeeklyCateringMenuTemplateComponent,
    ],
    providers: [],
    exports: [
        CateringMenuTemplateComponent,
        WeeklyCateringMenuTemplateComponent
    ]
})
export class MenuModule { }
