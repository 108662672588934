import { NgModule } from '@angular/core';
import { ShoppingCartRoutingModule } from './shopping-cart-routing.module';
import { ShoppingCartComponent } from './shopping-cart.component';
import { SharedModule } from '../shared-module/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { UtilityService } from '../services/utility.service';

@NgModule({
  imports: [
    ShoppingCartRoutingModule,
    FormsModule,
    CommonModule,
    MatBadgeModule
  ],
  exports: [
    ShoppingCartComponent
  ],
  declarations: [
    ShoppingCartComponent
  ],
  providers: [
    UtilityService
  ],
})
export class ShoppingCartModule  { }