import {Component} from '@angular/core';
import {FirebaseService} from 'src/app/services/firebase.service';

@Component({
    selector: 'app-weekly-catering-menu-template',
    templateUrl: './weekly-catering-menu-template.component.html',
    styleUrls: ['./weekly-catering-menu-template.component.scss']
})
export class WeeklyCateringMenuTemplateComponent {
    weeklyCateringMenu: any = {
        title: 'Weekly deal for Sunday - 18/12/2022',
        dealOptions: [{
            description: 'Option #1 (price $70)',
            items: ['Entree: Chicken Short Stick, Papdi Chaat',
                'Main: Chicken Polao, Beef Kala Bhuna, Rejala (B/G), Jali Kabab (B/C/F), Mix Vegetable, Mix Salad, Mango Pickle',
                'Dessert: Caramel Pudding, Cham Cham ',
                'Drinks: Water (600ml) or Coke/Pepsi (250ml)',
                'Cutlery: Cup, Plate, Spoon, Tissues',
                'Msc: Please let us know',
                'Service: No']
        },
            {
                description: 'Option #2 (price $70)',
                items: ['Entree: Chicken Short Stick, Papdi Chaat',
                    'Main: Chicken Polao, Beef Kala Bhuna, Rejala (B/G), Jali Kabab (B/C/F), Mix Vegetable, Mix Salad, Mango Pickle',
                    'Dessert: Caramel Pudding, Cham Cham ',
                    'Drinks: Water (600ml) or Coke/Pepsi (250ml)',
                    'Cutlery: Cup, Plate, Spoon, Tissues',
                    'Msc: Please let us know',
                    'Service: No']
            }
        ]
    };

    constructor(public firebaseService: FirebaseService) {
    }

    getItemType(item: any) {
        return item.split(':')[0];
    }

    getItemDescription(item: any) {
        return item.split(':')[1];
    }
}

