import {Component, Input} from '@angular/core';
import {FirebaseService} from 'src/app/services/firebase.service';
import {CateringMenu} from '../../login/_models/MenuItem';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-catering-menu-template',
    templateUrl: './catering-menu-template.component.html',
    styleUrls: ['./catering-menu-template.component.scss']
})
export class CateringMenuTemplateComponent {
    @Input() isDeleteVisible: boolean | null | undefined;
    cateringMenuItems: any[] = [];
    isAddCateringDialogVisible = false;
    cateringMenu: CateringMenu = new CateringMenu();
    cateringMenuError: any = {};
    subscriptions: Subscription = new Subscription();

    constructor(public firebaseService: FirebaseService) {
        this.getCateringMenuItems();
    }

    getCateringMenuItems() {
        this.subscriptions.add(
            this.firebaseService.getCateringMenuItems().subscribe(data => {
                this.cateringMenuItems = [];
                this.cateringMenuItems = data?.map(x => ({id: x.payload.doc.id, data: x.payload.doc.data()}))
                    .map((value: any) =>
                        ({
                            isEditButtonClicked: false,
                            isVisible: value.data.isVisible,
                            price: value.data.price,
                            qty: value.data.qty,
                            items: value.data.items,
                            title: value.data.title,
                            id: value.id,
                            description: value.data.description,
                            showDescription: value.data.showDescription
                        })
                    );
            }));
    }

    cancelCateringMenu() {
        this.cateringMenu = new CateringMenu();
        this.cateringMenuError = {};
        this.isAddCateringDialogVisible = false;
    }

    onValueChange(e: any, index: number) {
        this.cateringMenu.items[index] = e.target.value;
    }

    removeCateringMenuItem(index: number) {
        this.cateringMenu.items.splice(index, 1);
    }

    addCateringMenuItem() {
        this.cateringMenu.items.push('');
    }

    deleteCateringMenu(item: any) {
        this.firebaseService.deleteCateringMenuItem(item.id).then(
            res => {
                console.log(res);
            }
        );
    }

    updateCateringMenu(item: any) {
        this.firebaseService.updateCateringMenu(item.id, item).then(
            res => {
                console.log(res);
                item.isEditButtonClicked = false;
                this.isAddCateringDialogVisible = false;
            }
        );
    }

    addCateringMenu() {
        this.cateringMenuError.touched = true;
        if (this.isValidCatering()) {
            this.cateringMenu.showDescription = !this.cateringMenu.description ? false : this.cateringMenu.showDescription;
            this.firebaseService.addCateringMenu(this.cateringMenu).then(
                res => {
                    this.cateringMenu = new CateringMenu();
                }
            );
        }
    }

    isValidCatering() {
        this.cateringMenuError.items = !this.cateringMenu.items.length;
        this.cateringMenuError.title = !this.cateringMenu.title;
        this.cateringMenuError.qty = !this.cateringMenu.qty;
        this.cateringMenuError.price = !this.cateringMenu.price;

        this.cateringMenuError.isInvalid = this.cateringMenuError.items || this.cateringMenuError.title
            || this.cateringMenuError.qty || this.cateringMenuError.price;

        return !this.cateringMenuError.isInvalid;
    }

    addNewCateringMenuClicked() {
        this.isAddCateringDialogVisible = true;
    }

    hasAnyEditButtonClicked() {
        return this.cateringMenuItems.some(item => item.isEditButtonClicked);
    }

    editButtonClicked(item: any) {
        item.isEditButtonClicked = true;
        this.cateringMenu = item;
        this.isAddCateringDialogVisible = true;
    }

    cancelUpdate(item: any) {
        item.isEditButtonClicked = false;
        this.cateringMenu = new CateringMenu();
        this.isAddCateringDialogVisible = false;
    }

    updateCateringMenuItem(m: any) {
        this.firebaseService.updateCateringMenu(m.id, m).then(res => {
            console.log(res);
        });
    }

    getItemType(item: any) {
        return item.split(':')[0];
    }

    getItemDescription(item: any) {
        return item.split(':')[1];
    }
}

