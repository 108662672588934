import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-menu-item-header',
  templateUrl: 'menu-item-header.component.html',
  styleUrls: ['menu-item-header.component.scss']
})
export class MenuItemHeaderComponent {

  @Input() headerName = '';
  @Input() headerComment: string = null;

}

