export class OrderDetail{
  id: number;
  customerName: string;
  customerPhone: string;
  customerAddress: string;
  email: string;
  details:string;
  paymentMethod: string;
  deliveryOption: string;
  subject: string;

}