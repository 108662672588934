<ng-container *ngIf="isDeleteVisible === true">
  <div *ngIf="!isAddCateringDialogVisible" class="row" style="float: right;padding-right: 26px;padding-top: 10px;">
    <button (click)="addNewCateringMenuClicked()" mat-raised-button>Add Catering Menu</button>
  </div>

  <div *ngIf="isAddCateringDialogVisible" style="display:flex;justify-content: center;">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Catering menu</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <div class="field">
            <div class="field-label">Title</div>
            <div class="field-value">
              <input [(ngModel)]="cateringMenu.title" [ngClass]="{'error-border': cateringMenuError.title === true}"
                     style="width: 320px;" type="text">
            </div>
          </div>

          <div class="field">
            <div class="field-label">
              Show Description <input [(ngModel)]="cateringMenu.showDescription" style="width: 32px;"
                                      type="checkbox">
            </div>

            <div class="field-label">Description</div>
            <div class="field-value">
                                <textarea [(ngModel)]="cateringMenu.description"
                                          style="width: 320px;height: 100px;"></textarea>
            </div>
          </div>

          <div class="field">
            <div class="field-label">Items</div>
            <div (click)="addCateringMenuItem()" class="field-label"
                 style="float: right; margin-top: -22px; cursor: pointer">(+)
            </div>
            <div *ngFor="let item of cateringMenu.items; let i = index" class="field-value">
              <input (change)="onValueChange($event, i)" [ngClass]="{'error-border': cateringMenuError.items === true}"
                     [value]="cateringMenu.items[i]"
                     style="width: 310px;" type="text">
              <div (click)="removeCateringMenuItem(i)" *ngIf="i>0" class="field-label"
                   style="float: right;  cursor: pointer">(-)
              </div>
            </div>
          </div>

          <div class="field">
            <div class="field-label">Qty</div>
            <div class="field-value">
              <input [(ngModel)]="cateringMenu.qty" [ngClass]="{'error-border': cateringMenuError.qty === true}"
                     style="width: 320px;" type="text">
            </div>
          </div>

          <div class="field">
            <div class="field-label">Price</div>
            <div class="field-value">
              <input [(ngModel)]="cateringMenu.price" [ngClass]="{'error-border': cateringMenuError.price === true}"
                     style="width: 320px;" type="text">
            </div>
          </div>

          <div class="field">
            <div class="field-label"></div>
            <div class="field-value">
              IsVisible <input [(ngModel)]="cateringMenu.isVisible" style="width: 32px;" type="checkbox">
            </div>
          </div>
        </div>
        <div *ngIf="cateringMenuError.touched && cateringMenuError.isInvalid" class="row"
             style="color: red; font-size: 10px;margin-left: 20px;">
          *Please correct the above error(s)
        </div>
        <div *ngIf="!hasAnyEditButtonClicked()" class="row" style="justify-content: center;">
          <button (click)="addCateringMenu()" mat-raised-button>Add</button>
          <button (click)="cancelCateringMenu()" mat-raised-button>Cancel</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div style="margin-top: 85px"></div>
</ng-container>

<div class="wrapper">
  <div *ngFor="let m of cateringMenuItems">
    <div class="menu-card">
      <div *ngIf="isDeleteVisible === true" style="cursor:pointer;float: right; margin-top: -53px;border: 2px solid greenyellow;
    padding: 2px;">
        <div style="color:black; font-size: 16px;">
          <input (change)="updateCateringMenuItem(m)" [(ngModel)]="m.isVisible" type="checkbox">
          <div (click)="deleteCateringMenu(m)" style="color:red; font-size: 16px; float: right; padding-left: 10px;">
            Delete(x)
          </div>
          <div (click)="editButtonClicked(m)" *ngIf="!m.isEditButtonClicked"
               style="font-size: 16px; float: right; padding-left: 10px;">
            Edit
          </div>
          <div *ngIf="m.isEditButtonClicked" style="font-size: 16px; float: right; padding-left: 10px;">
            <span (click)="updateCateringMenu(m)">Save/</span>
            <span (click)="cancelUpdate(m)">Cancel</span>
          </div>
        </div>
      </div>
      <div class="menu-box">
        <div class="menu-text">
          <div class="menu-title"> {{m.title}} </div>
          <div class="menu-price">${{m.price}}</div>
        </div>
        <div class="menu-description"> {{m.description}}</div>
        <br/>
        <div *ngFor="let item of m.items" class="menu-description">
          <span class="item-type">{{getItemType(item)}}: </span>
          <span>{{getItemDescription(item)}}</span>
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</div>

