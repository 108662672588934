<!--<app-order-online></app-order-online>-->

<div class="input-group">
    <input (input)="onSearchChange()" [(ngModel)]="searchedText" class="form-control" placeholder="Search menu..."
           type="text">
    <div class="input-group-append">
        <button class="btn btn-secondary" type="button">
            <i class="fa fa-search"></i>
        </button>
    </div>
</div>

<mat-tab-group>
    <ng-container *ngFor="let item of menuItems">
        <mat-tab [label]="item.category">
            <app-menu-item-header [headerName]="item.category" class="col-md-12"></app-menu-item-header>
            <app-menu-template (deleteMenuTemplate)="_deleteMenu($event)" [isDeleteVisible]="isDeleteVisible"
                               [menuItems]="item.value"></app-menu-template>
        </mat-tab>
    </ng-container>
</mat-tab-group>
