<app-menu-item-header headerName="Restaurant Gallery" headerComment="An Authentic Bengali Recipe"></app-menu-item-header>



    <owl-carousel-o [options]="customOptions">
 
    <ng-container *ngFor="let slide of slidesStore">
      <ng-template carouselSlide>
        <div class="slide">
          <img [src]="slide.src" [title]="slide.title" style="padding: 10px;">
        </div>
      </ng-template>
    </ng-container>
 
  </owl-carousel-o>



