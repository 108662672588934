import {Component, OnDestroy} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FirebaseService} from './services/firebase.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
    title = 'bddineAngularNew';
    subscriptions: Subscription = new Subscription();
    bannerText = 'Due to the price increase of food items, we have revised our menu with a small price increase.';

    constructor(private db: AngularFirestore,
                private fireBaseService: FirebaseService
    ) {
        this.fireBaseService.getBannerText().subscribe(data => {
            const allBannerTexts = data?.map(x => x.payload.doc.data());
            this.bannerText = allBannerTexts.filter(b => b.showBanner)
                .sort((a, b) => a.sortOrder - b.sortOrder)?.[0]?.bannerText;
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
