<app-gallery-home-top></app-gallery-home-top>

<div class="home" style="clear: both;">

  <div class="menu-button">
    <div  class="menu-item menu-link" (click)="getSelectedMenu(1)" [ngClass]="{'menu-active': selectedMenu === 1}">
      Catering
    </div>
    <div  class="menu-item menu-link" (click)="getSelectedMenu(2)" [ngClass]="{'menu-active': selectedMenu === 2}" >
      Weekly Catering
    </div>
    <div  class="menu-item menu-link" (click)="goToMenu()" [ngClass]="{'menu-active': selectedMenu === 3}">
      Menu
    </div>
    <div (click)="goToYoello()" [ngClass]="{'menu-active': selectedMenu === 4}" class="menu-item menu-link">
        Order and pay
    </div>

  </div>

  <div id="aboutus">
  <app-home-about-us></app-home-about-us>
  </div>
  <div id="catering">
  <app-home-catering></app-home-catering>
  </div>
  <div id="weeklycatering">
  <app-home-weekly-catering></app-home-weekly-catering>
  </div>

  <div style="overflow: hidden; clear: both;"></div>

  <app-gallery-new></app-gallery-new>
  <app-footer></app-footer>

</div>
