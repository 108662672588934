<div id="menu">
  <ng-container *ngIf="isLoaded && trendingMenu.length > 0">
    <app-menu-item-header class="col-md-12" headerName="Trending" headerComment="Your favorite special">
    </app-menu-item-header>
  
    <app-order-template [menuItems]="trendingMenu" (menuClicked)="menuClicked($event)"></app-order-template>
  </ng-container>
  
  <ng-container *ngIf="isLoaded && entreeMenu.length > 0">
    <app-menu-item-header class="col-md-12" headerName="Entree" headerComment="Your favorite special">
    </app-menu-item-header>
  
    <app-order-template [menuItems]="entreeMenu" (menuClicked)="menuClicked($event)"></app-order-template>
  </ng-container>
  
  <ng-container *ngIf="isLoaded && breakfastMenu.length > 0">
    <app-menu-item-header class="col-md-12" headerName="Breakfast" headerComment="Your favorite special">
    </app-menu-item-header>
  
    <app-order-template [menuItems]="breakfastMenu" (menuClicked)="menuClicked($event)"></app-order-template>
  </ng-container>
  
  <ng-container *ngIf="isLoaded && lunchMenu.length > 0">
    <app-menu-item-header class="col-md-12" headerName="Lunch" headerComment="Your favorite special">
    </app-menu-item-header>
  
    <app-order-template [menuItems]="lunchMenu" (menuClicked)="menuClicked($event)"></app-order-template>
  </ng-container>
  
  <ng-container *ngIf="isLoaded && dinnerMenu.length > 0">
    <app-menu-item-header class="col-md-12" headerName="Dinner" headerComment="Your favorite special">
    </app-menu-item-header>
  
    <app-order-template [menuItems]="dinnerMenu" (menuClicked)="menuClicked($event)"></app-order-template>
  </ng-container>
  
  <ng-container *ngIf="isLoaded && cateringMenu.length > 0">
    <app-menu-item-header class="col-md-12" headerName="Catering" headerComment="Your favorite special">
    </app-menu-item-header>
  
    <app-order-template [menuItems]="cateringMenu" (menuClicked)="menuClicked($event)"></app-order-template>
  </ng-container>
  
  <ng-container *ngIf="isLoaded && weeklyCateringMenu.length > 0">
    <app-menu-item-header class="col-md-12" headerName="Weekly Catering" headerComment="Your favorite special">
    </app-menu-item-header>
  
    <app-order-template [menuItems]="weeklyCateringMenu" (menuClicked)="menuClicked($event)"></app-order-template>
  </ng-container>
</div>