import {Component, ElementRef, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {FirebaseService} from '../services/firebase.service';
import {ModalService} from '../services/modal.service';
import {AuthService} from '../login/_services/auth.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class NavMenuComponent implements OnInit {
  isSidebarOpen = false;
  isUserLoggedIn = false;
  isAdminLoggedIn = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    // tslint:disable-next-line:variable-name
    private _eref: ElementRef,
    private router: Router,
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private modalService: ModalService) { }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.isSidebarOpen = false;
    }
  }


  ngOnInit() {
   this.authService.currentUser.subscribe(d => {
     this.isUserLoggedIn =  this.authService.isLoggedIn || (d != null && d.emailVerified && !this.authService.admin.includes(d.email));
     this.isAdminLoggedIn = this.authService.isAdminLoggedIn || (d != null && d.emailVerified && this.authService.admin.includes(d.email));
   });
  }

  logout() {
    this.authService.SignOut();
  }

  goToContactUs() {
    this.firebaseService.setViewPort('contact-us');
  }

  goToMenu(): void {
    this.router.navigate(['fullmenu', 'menuitem'], {skipLocationChange: true});
  }

  goToYoello() {
    this.document.location.href = 'https://pay.yoello.com/v/bddine';
  }

  goToFoodhub() {
    this.document.location.href = 'https://bddineonline.com.au/order-now';
  }
}

