import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ModalService } from 'src/app/services/modal.service';


@Component({
  selector: 'app-order-online',
  templateUrl: './order-online.component.html',
  styleUrls: ['./order-online.component.scss']
})
export class OrderOnlineComponent implements OnInit, OnDestroy {

  constructor(public firebaseService: FirebaseService, 
    public modalService: ModalService) {

  }

  allMenuItems: any[] = [];

  entreeMenu: any[] = [];
  trendingMenu: any[] = [];
  lunchMenu: any[] = [];
  breakfastMenu: any[] = [];
  dinnerMenu: any[] = [];
  cateringMenu: any[] = [];
  weeklyCateringMenu: any[] = [];

  isLoaded: boolean = false;

  ngOnInit() {
    this.getMenuData();
    this.firebaseService.getViewPort().subscribe((d: any) => {
      if (d === 'menu') {
        var elmnt = document.getElementById(d);
        elmnt.scrollIntoView();
      }
    });
  }


  getMenuData(){
    this.firebaseService.getAllMenuItems().subscribe(data => {
      this.allMenuItems = data.map(x => { return { id: x.payload.doc.id, data: x.payload.doc.data() } });
      
      this.lunchMenu = this.allMenuItems.filter(x => x.data.section === 'lunch' && x.data.isVisible);
      this.entreeMenu = this.allMenuItems.filter(x => x.data.section === 'entree' && x.data.isVisible);
      this.trendingMenu= this.allMenuItems.filter(x => x.data.section === 'trending' && x.data.isVisible);
      this.breakfastMenu= this.allMenuItems.filter(x => x.data.section === 'breakfast' && x.data.isVisible);
      this.dinnerMenu= this.allMenuItems.filter(x => x.data.section === 'dinner' && x.data.isVisible);
      this.cateringMenu= this.allMenuItems.filter(x => x.data.section === 'catering' && x.data.isVisible);
      this.weeklyCateringMenu= this.allMenuItems.filter(x => x.data.section === 'weeklycatering' && x.data.isVisible);
      this.isLoaded = true;
    });
  }

  menuClicked(item: any) {
    this.firebaseService.setValue(item);
  }

  ngOnDestroy(){

  }



}

