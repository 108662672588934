import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MenuItemHeaderComponent } from './menu-item-header.component';
import { FirebaseService } from '../services/firebase.service';
import { ModalComponent } from '../home/gallery/modal.component';
import { ModalService } from '../services/modal.service';
import { OrderOnlineComponent } from '../shopping-cart/order-online.component';
import { OrderTemplateComponent } from '../shopping-cart/order-template.component';
import { ShoppingCartModule } from '../shopping-cart/shopping-cart.module';
import { MenuTemplateComponent } from '../menu/menu-template/menu-template.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  imports: [
    FormsModule,
    MatMenuModule,
    CarouselModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    ReactiveFormsModule,
    ShoppingCartModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    CarouselModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    MatTabsModule,
    ShoppingCartModule,
    MenuItemHeaderComponent,
    ModalComponent,
    OrderOnlineComponent,
    OrderTemplateComponent,
    MenuTemplateComponent
  ],
  declarations:[

    MenuItemHeaderComponent,
    ModalComponent,
    OrderOnlineComponent,
    OrderTemplateComponent,
    MenuTemplateComponent
  ],
  providers:[ ModalService]

})
export class SharedModule { }