import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ModalService } from 'src/app/services/modal.service';
import { FirebaseService } from 'src/app/services/firebase.service';

interface Image {
  src: string;
  title?: string;
  alt?: string;
}

@Component({
  selector: 'app-gallery-home-top',
  templateUrl: './gallery-home-top.component.html',
  styleUrls: ['./gallery-home-top.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class GalleryHomeTopComponent implements OnInit{
 
  showTopText = false;
  isAvailable = false;

  
  constructor(private modalService: ModalService, private firebaseService: FirebaseService) { }

  ngOnInit() {
  }

  openOrderModal(id: string){
    this.modalService.open(id);
  }
  closeModal(id: string){
    this.modalService.close(id);
  }
  
  goToBookTable(){
    this.firebaseService.setViewPort('book-table');
  }

  mouseEnter(e:any){
    this.firebaseService.setFloatButton(false);
  }


}

