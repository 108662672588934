import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Subscription} from 'rxjs';
import {FirebaseService} from '../../services/firebase.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  openingHours: any[] = [];
  subscriptions: Subscription = new Subscription();

    constructor(private db: AngularFirestore, private firebaseService: FirebaseService) {
    }

ngOnInit() {
  this.subscriptions.add(
      this.firebaseService.getOpeningHours().subscribe(data => {
          this.openingHours = [];
          this.openingHours = data?.map(x => x.payload.doc.data());
          this.openingHours.sort((a, b) => a.sortOrder - b.sortOrder);
      }));
}

ngOnDestroy() {
  this.subscriptions.unsubscribe();
}

}

