<div class="displayTable">
    <div class="displayTableCell">
  
      <div class="authBlock">
        <h3>Sign Up</h3>
  
        <div class="formGroup">
            <input type="text" class="formControl" placeholder="Name" #userName required>
          </div>

        <div class="formGroup">
          <input type="email" class="formControl" placeholder="Email Address" #userEmail required>
        </div>
  
        <div class="formGroup">
          <input type="password" class="formControl" placeholder="Password" #userPwd required>
        </div>
  
        <div class="formGroup">
          <button [disabled]="authService.loading" class="btn btn-primary" (click)="authService.SignUp(userName.value, userEmail.value, userPwd.value)">
            <span *ngIf="authService.loading" class="spinner-border spinner-border-sm mr-1"></span>
            Sign Up
          </button>
        
        </div>
  
        <div class="formGroup">
          <span class="or"><span class="orInner">Or</span></span>
        </div>
  
        <!-- Continue with Google -->
        <div class="formGroup">
          <button type="button" class="btn googleBtn" (click)="authService.GoogleAuth()">
            <i class="fab fa-google-plus-g"></i>
            Continue with Google
          </button>
        </div>
  
        <!-- Continue with Facebook -->
       <!--  <div class="formGroup">
          <button type="button" class="btn facebookBtn" (click)="authService.GoogleAuth()">
            <i class="fab fa-facebook"></i>
            Continue with Facebook
          </button>
        </div> -->
      </div>
  
      <div class="redirectToLogin">
        <span>Already have an account? <span class="redirect" routerLink="/login" skipLocationChange>Log In</span></span>
      </div>
    </div>
  
  </div>