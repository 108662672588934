import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FirebaseService} from '../services/firebase.service';
import {ModalService} from '../services/modal.service';
import {Subscription} from 'rxjs';
import {UtilityService} from '../services/utility.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();
    searchedText = '';
    backUpMenuItems: any[] = [];

    @Output() deleteMenu: EventEmitter<any> = new EventEmitter<any>();
    @Input() isDeleteVisible = false;
    categories: any[] = [];
    menuItems: any[] = [];

    constructor(public firebaseService: FirebaseService,
                private utilService: UtilityService,
                public modalService: ModalService) {
    }

    ngOnInit() {
        this.getMenuData();
        this.firebaseService.getViewPort().subscribe((d: any) => {
            if (d === 'menu') {
                const elmnt = document.getElementById(d);
                elmnt.scrollIntoView();
            }
        });
    }

    getMenuData() {
        this.subscriptions.add(
            this.firebaseService.getAllCategories().subscribe(categories => {
                this.categories = this.utilService.getProcessedCategories(categories);
                this.subscriptions.add(
                    this.firebaseService.getAllMenuItems()
                        .subscribe(data => {
                            this.menuItems =
                                this.utilService.getCategorizedMenuItems(data, this.categories);
                            this.backUpMenuItems = JSON.parse(JSON.stringify(this.menuItems));
                        }));
            }));
    }

    onSearchChange() {
        this.menuItems = JSON.parse(JSON.stringify(this.backUpMenuItems));
        const searchedValues = [];
        this.menuItems.forEach(menuItem => {
            const values = menuItem.value.filter(m => m.title.toLowerCase()
                .includes(this.searchedText.toLowerCase()));
            if (values?.length) {
                searchedValues.push({
                    category: menuItem.category,
                    value: values
                });
            }
        });
        if (this.searchedText) {
            this.menuItems = searchedValues;
        }
    }

    _deleteMenu(item: any) {
        this.deleteMenu.emit(item);
    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}

