<div class="wrapper">
    <div>
        <div class="menu-card">
            <div class="menu-box">
                <div class="menu-text">
                    <div class="menu-title"> {{weeklyCateringMenu.title}} </div>
                </div>
                <ng-container *ngFor="let option of weeklyCateringMenu.dealOptions">
                    <br/>
                    <div class="menu-description"> {{option.description}}</div>
                    <br/>
                    <div *ngFor="let item of option.items" class="menu-description">
                        <span class="item-type">{{getItemType(item)}}: </span>
                        <span>{{getItemDescription(item)}}</span>
                        &nbsp;
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

